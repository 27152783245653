import React, { Component } from 'react';
import MdCheckmarkCircleOutline from 'react-ionicons/lib/MdCheckmarkCircleOutline';

class OrderInfo extends Component {
    render() {
        return (
            <div className="row mb-4 ml-1 mr-1 mt-4">
                <div className="col-12">
                    {
                        this.props.isMedical != true && (
                    <div>        
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                    To secure your booking, a payment of one month’s rent and a refundable security deposit (equivalent to two months’ rent) is required upfront.                    </span>
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                    After completing the payment, please upload your address and ID proof, along with a recent bank statement, for KYC verification. We’ll proceed with shipping once the documents are verified.                    </span>
                    </div>)
                    }
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                    Your billing cycle will start from the delivery date of your rental items.
                    </span>
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                    Please note: The minimum rental amount is ₹1,000 for orders in Delhi and Manesar, ₹500 for orders in Gurugram, and ₹1,500 for orders in Noida and Ghaziabad.
                    </span>
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                    The auto-debit for the rental charges shall be activated immediately upon the successful delivery of the items.
                    </span>
                    
                    {
                        this.props.isMedical==true && (
                            <div>
                                <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                                    Rates are without gst ( 18% gst will be applied).	
                                </span>
                                <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                                    Delivery charges will be according to the distance.
                                </span>
                                <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                                    Post delivery check will be collected at the time of delivery equivalent to cost of equipment.
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default OrderInfo;